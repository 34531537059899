import {
  Box,
  Text,
  Stack,
  Flex,
  useDisclosure,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Icon,
} from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Link from 'next/link'
import React, { useState } from 'react'
import Image from 'next/image'

import useTranslation from 'next-translate/useTranslation'

import DesktopAccountMenu from './DesktopAccountMenu'
import MobileAccountMenu from './MobileAccountMenu'
import { MdPlace } from 'react-icons/md'
import { IconType } from 'react-icons'
import { IoHomeSharp, IoSearchSharp, IoTicketSharp } from 'react-icons/io5'
import { GiGuitarBassHead } from 'react-icons/gi'
import { BsFillCalendarWeekFill } from 'react-icons/bs'

const LocaleSelector = dynamic(() => import('./LocaleSelector'), { ssr: false })

interface NavItemProps {
  icon?: IconType
  text: string
  to: string
}

export const NAVBAR_WIDTH = 200
export const NAVBAR_MOBILE_HEIGHT = 78

function NavItem({ icon, text, to }: NavItemProps) {
  const router = useRouter()
  const [isMouseOver, setIsMouseOver] = useState(false)
  const { asPath } = useRouter()
  const isHome = to === '/'
  const isSelected = (isHome ? asPath === '/' : asPath.startsWith(to)) || isMouseOver

  return (
    <Link href={to}>
      <Box
        flex={1}
        cursor="pointer"
        alignSelf="center"
        padding={['4px', null, '12px']}
        marginBottom={['8px', null, 0]}
        w="100%"
        h="100%"
        fontSize="lg"
        marginInlineStart={['8px !important', null, '16px']}
        color={isSelected ? 'red.300' : '#c1bcb9'}
        _hover={{ bg: '#282828', color: '#e06354' }}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <Flex direction={['column', null, 'row']} justifyContent={['center', null, 'flex-start']} alignItems="center">
          {icon && (
            <Box marginTop={[0, null, '8px']} marginRight={[0, null, '8px']}>
              <Icon as={icon} size="32px" color={isSelected ? 'red.300' : 'white'} />
            </Box>
          )}
          <Text
            fontWeight="bold"
            paddingTop={[0, null, router.locale === 'ko' ? '6px' : '2px']}
            fontSize={[router.locale === 'ja' ? '10px' : 'xs', 'sm', 'large']}
          >
            {text}
          </Text>
        </Flex>
      </Box>
    </Link>
  )
}

export default function NavBar() {
  const { isOpen: isVisibleAccountMenu, onOpen: onVisibleAccountMenu, onClose: onHideAccountMenu } = useDisclosure()
  const { isOpen: isLocaleSelectorOpen, onOpen: onLocaleSelectorOpen, onClose: onLocaleSelectorClose } = useDisclosure()
  const { t } = useTranslation('common')

  return (
    <Flex flexDir="column" bgColor="black" height={['auto', null, '100%']} justifyContent="space-between">
      <nav>
        <Box
          width={['100%', null, `${NAVBAR_WIDTH}px`]}
          position={['fixed', null, 'relative']}
          left={[0, null, 'inherit']}
          bottom={[0, null, 'inherit']}
          zIndex={[10, null, 'inherit']}
          bgColor="black"
        >
          <Stack
            flex="1"
            width="100%"
            h={['auto', null, '100%']}
            direction={['row', null, 'column']}
            spacing="16px"
            justifyContent="space-between"
          >
            <Box mt="24px" h="60px" textAlign="center" display={['none', 'block']} position="relative">
              <Link href="/">
                <Image src="/images/logo-text.png" layout="fill" objectFit="contain" alt="인디스트릿 로고" priority />
              </Link>
            </Box>
            <NavItem icon={IoHomeSharp} to="/home" text={t('home')} />
            <NavItem icon={GiGuitarBassHead} to="/musicians" text={t('musician')} />
            <NavItem icon={IoTicketSharp} to="/live" text={t('live')} />
            <NavItem icon={BsFillCalendarWeekFill} to="/weekly" text={t('weekly-live')} />
            <NavItem icon={MdPlace} to="/venues" text={t('venue')} />
            <NavItem icon={IoSearchSharp} to="/search" text={t('search')} />
            <MobileAccountMenu
              mobileNavbarHeight={NAVBAR_MOBILE_HEIGHT}
              isVisibleAccountMenu={isVisibleAccountMenu}
              onVisibleAccountMenu={onVisibleAccountMenu}
              onHideAccountMenu={onHideAccountMenu}
              onLocaleSelectorOpen={onLocaleSelectorOpen}
            />
          </Stack>
        </Box>
      </nav>
      <Modal size="xs" isOpen={isLocaleSelectorOpen} onClose={onLocaleSelectorClose}>
        <ModalContent
          position="fixed"
          left={[0, 'auto', 0]}
          right={['auto', '16px', 'auto']}
          bottom={['10px', '80px', '20px']}
          w={['100%', '200px']}
          margin={[null, null, 0]}
          maxW={['100%', '20rem']}
        >
          <ModalHeader>{t('change-language')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <LocaleSelector
              onChange={() => {
                onHideAccountMenu()
                onLocaleSelectorClose()
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <DesktopAccountMenu onLocaleSelectorOpen={onLocaleSelectorOpen} />
    </Flex>
  )
}
