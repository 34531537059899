import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { Flex, Button, Text, Box, HStack, Spinner } from '@chakra-ui/react'
import { VscPreview, VscSignIn } from 'react-icons/vsc'
import { FaLanguage } from 'react-icons/fa'
import { RiLogoutBoxLine } from 'react-icons/ri'
import { signOut } from 'web-queries/src/utils/user'

import useUserContext from '../hooks/useUserContext'
import Link from 'next/link'

interface Props {
  onLocaleSelectorOpen: () => void
}

export default function DesktopAccountMenu({ onLocaleSelectorOpen }: Props) {
  const router = useRouter()
  const { t } = useTranslation()
  const { isAuthenticated, isInitialized } = useUserContext()

  return (
    <Flex flexDirection="column" display={['none', null, 'inherit']} justifyContent="center" padding="0 8px 24px 8px">
      <Button marginBottom="16px" onClick={onLocaleSelectorOpen}>
        <HStack>
          <FaLanguage size="36px" />
          <Box marginLeft="8px">{t('common:change-language')}</Box>
        </HStack>
      </Button>
      {!isInitialized && (
        <Flex w="100%" justifyContent="center">
          <Spinner w="40px" h="40px" />
        </Flex>
      )}
      {isInitialized &&
        (isAuthenticated ? (
          <Button
            onClick={() => {
              signOut()
              router.reload()
            }}
          >
            <HStack>
              <RiLogoutBoxLine size="24px" />
              <Box marginLeft="8px">{t('common:signout')}</Box>
            </HStack>
          </Button>
        ) : (
          <>
            <Link href="/signup">
              <Button w="100%" marginBottom="16px">
                <Flex justifyContent="space-between">
                  <VscPreview />
                  <Text>{t('common:signup')}</Text>
                </Flex>
              </Button>
            </Link>
            <Link href={`/signin?redirect=${encodeURIComponent(location.pathname)}${location.search}`}>
              <Button w="100%">
                <Flex justifyContent="space-between">
                  <VscSignIn />
                  {t('common:signin')}
                </Flex>
              </Button>
            </Link>
          </>
        ))}
    </Flex>
  )
}
