import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { Box, Button, Stack, Flex, Text } from '@chakra-ui/react'
import { VscPreview, VscSignIn } from 'react-icons/vsc'
import { HiDotsVertical } from 'react-icons/hi'
import { FaLanguage } from 'react-icons/fa'
import { RiLogoutBoxLine } from 'react-icons/ri'
import { signOut } from 'web-queries/src/utils/user'

import useUserContext from '../hooks/useUserContext'

interface Props {
  mobileNavbarHeight: number
  isVisibleAccountMenu: boolean
  onVisibleAccountMenu: () => void
  onHideAccountMenu: () => void
  onLocaleSelectorOpen: () => void
}

export default function MobileAccountMenu({
  mobileNavbarHeight,
  isVisibleAccountMenu,
  onVisibleAccountMenu,
  onHideAccountMenu,

  onLocaleSelectorOpen,
}: Props) {
  const router = useRouter()
  const { t } = useTranslation()
  const { isAuthenticated } = useUserContext()

  return (
    <>
      <Box
        w="40px"
        h="100%"
        marginRight={[0, null, '6']}
        display={['flex', null, 'none']}
        marginInlineStart={['8px !important', null, '16px']}
        paddingTop="4px"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          className="mobileAccountMenu"
          padding="0"
          bgColor="#000"
          marginRight="4px"
          w="100%"
          h="66px"
          aria-label="계정 메뉴 버튼"
          onClick={() => {
            if (isVisibleAccountMenu) {
              onHideAccountMenu()
            } else {
              onVisibleAccountMenu()
            }
          }}
        >
          <HiDotsVertical size={24} />
        </Button>
      </Box>
      <Box
        position="fixed"
        zIndex={2001}
        display={[isVisibleAccountMenu ? 'flex' : 'none', null, 'none']}
        flexDirection="column"
        right="70px"
        w={['100%', 'auto']}
        borderRadius="16px"
        bottom={`${mobileNavbarHeight}px`}
        padding="16px"
        bgColor="gray.800"
        textAlign="right"
      >
        <Stack direction={['column', 'row']} textAlign="center" spacing="16px" padding="16px" marginLeft={['64px', 0]}>
          {isAuthenticated ? (
            <Button
              w={['100%', 'auto']}
              onClick={() => {
                signOut()
                router.reload()
              }}
            >
              <Flex w="100%" justifyContent="space-between">
                <RiLogoutBoxLine size="24px" />
                {t('common:signout')}
              </Flex>
            </Button>
          ) : (
            <>
              <Button
                w={['100%', 'auto']}
                onClick={() => {
                  onHideAccountMenu()
                  router.push('/signup')
                }}
              >
                <Flex w="100%" justifyContent="space-between">
                  <VscPreview />
                  <Text>{t('common:signup')}</Text>
                </Flex>
              </Button>
              <Button
                w={['100%', 'auto']}
                onClick={() => {
                  onHideAccountMenu()
                  router.push(`/signin?redirect=${encodeURIComponent(location.pathname)}${location.search}`)
                }}
              >
                <Flex w="100%" justifyContent="space-between">
                  <VscSignIn />
                  {t('common:signin')}
                </Flex>
              </Button>
            </>
          )}
          <Button w={['100%', 'auto']} onClick={onLocaleSelectorOpen}>
            <Flex w="100%" justifyContent="space-between" alignItems="center">
              <FaLanguage size="36px" />
              {t('common:change-language')}
            </Flex>
          </Button>
        </Stack>
      </Box>
    </>
  )
}
